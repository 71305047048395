import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  id?: string;
  title?: string;
  fullWidth?: boolean;
}
export default function HomePageSection({
  id,
  title,
  fullWidth = false,
  children,
}: PropsWithChildren<Props>) {
  return (
    <section
      id={id}
      className={clsx(styles.sectionContainer, fullWidth && styles.fullWidth)}
    >
      {title && <h2 className="titleL">{title}</h2>}
      {children}
    </section>
  );
}
