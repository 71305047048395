'use client';

import { useEffect, useRef, useState } from 'react';

import SendLogOnViewWrapper from '@/components/SendLogOnViewWrapper';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';

import styles from './styles.module.scss';

export default function Ticker() {
  const [startOffset, setStartOffset] = useState(-50);

  const medianRef = useRef<SVGPathElement | null>(null);
  const tickerRef = useRef<SVGSVGElement | null>(null);
  const entry = useIntersectionObserver(tickerRef, {});
  const isVisible = !!entry?.isIntersecting;

  const handleScroll = () => {
    requestAnimationFrame(() => {
      const tickerRect = tickerRef.current?.getBoundingClientRect();
      const pathLength = medianRef.current?.getTotalLength();

      if (tickerRect && pathLength) {
        const scrollPercent = tickerRect.y / window.innerHeight;
        const animationSpeed = 100; // The divisor number is arbitrary and is used to control the speed of the animation (the higher the number, the slower animation).
        const textOffsetUpdated =
          startOffset + (scrollPercent * pathLength) / animationSpeed; // use + or - to manage the direction of the animation.

        setStartOffset(textOffsetUpdated);
      }
    });
  };

  useEffect(() => {
    if (isVisible) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      setStartOffset(-50);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <SendLogOnViewWrapper event="ui_homepage_ticker_section_viewed">
      <div className={styles.tickerContainer}>
        <svg
          ref={tickerRef}
          className={styles.ticker}
          viewBox="0 0 4933.87 434.59"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            className={styles.tickerBanner}
            d="M2924.3,434.59c-239.78,0-441.16-51.05-654.35-105.09-239.38-60.68-486.92-123.43-823.81-123.43-199.89,0-424.67,17.38-650.06,50.27-250.08,36.49-498.86,91-739.45,162.03L0,226.54C249.65,152.84,507.77,96.28,767.2,58.43,1001.98,24.17,1236.75,6.07,1446.13,6.07c162.04,0,319.77,13.7,482.2,41.88,142.16,24.67,268.54,56.7,390.75,87.68,103.61,26.26,201.48,51.07,301.2,69.38,109.93,20.18,206.53,29.58,304.01,29.58,127.82,0,254.58-9.46,398.93-29.76,130.15-18.3,258.46-43.14,394.31-69.44C4045.32,71.93,4416.84,0,4933.87,0v200c-222.35,0-435.93,13.78-652.94,42.11-191.84,25.05-361.4,57.88-525.38,89.62-273.19,52.89-531.24,102.85-831.25,102.85Z"
          />
          <path
            id="ticker-median"
            ref={medianRef}
            className={styles.tickerMedian}
            d="M27.8,320.7c241-69.7,489.6-123.5,739.4-160,234.8-34.3,469.5-52.4,678.9-52.4s319.8,13.7,482.2,41.9c142.2,24.7,268.5,56.7,390.8,87.7,103.6,26.3,201.5,51.1,301.2,69.4,109.9,20.2,206.5,29.6,304,29.6s254.6-9.5,398.9-29.8c130.1-18.3,258.5-43.1,394.3-69.4,327.8-63.5,699.3-135.4,1216.3-135.4"
          />
          <text dy={20} className={styles.tickerText}>
            <textPath
              id="text-path"
              href="#ticker-median"
              className={styles.tickerTextpath}
              startOffset={`${startOffset}%`}
            >
              {Array.from(new Array(10)).map((_, index) => {
                const itemValue = index + 1;
                return (
                  <tspan key={itemValue}>
                    <tspan>Moments worth holding onto</tspan>
                    &nbsp; &nbsp;
                    <tspan className={styles.tickerTextpathHeart}>♥</tspan>
                    &nbsp; &nbsp;
                  </tspan>
                );
              })}
            </textPath>
          </text>
        </svg>
      </div>
    </SendLogOnViewWrapper>
  );
}
