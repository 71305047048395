'use client';

import clsx from 'clsx';

import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

export default function CtaButton() {
  return (
    <button
      className={clsx('bodyMedium', styles.mainCta)}
      type="button"
      onClick={() => {
        const el = document.getElementById('best-sellers');
        if (!el) return;
        document.documentElement.scroll({
          top: el.offsetTop,
          behavior: 'smooth',
        });
        sendLog('ui_homepage_hero_banner_cta_tapped');
      }}
    >
      Hit print!
    </button>
  );
}
