'use client';

import { useState } from 'react';
import clsx from 'clsx';

import SendLogOnViewWrapper from '@/components/SendLogOnViewWrapper';
import Video from '@/components/Video';
import { MOBILE_MEDIA_QUERY } from '@/constants';
import useMediaQuery from '@/hooks/useMediaQueries';
import { Product } from '@/types/products';
import getBasePath from '@/utils/getBasePath';
import getProductLowerUnitPrice from '@/utils/products/getProductLowerUnitPrice';

import HomePageSection from '../../_components/Section';

import Overlay, { PictaUserVideo } from './Overlay';

import styles from './styles.module.scss';

const PICTA_USERS_VIDEO: PictaUserVideo[] = [
  {
    id: '1',
    productId: 'print_4x6',
    title: 'Photo Print',
    subtitle: '4x6',
    url: '/product/prints?name=4x6',
    video: `${getBasePath()}/images/homepage/UGC/UGC_1.mp4`,
    productImage: `${getBasePath()}/images/homepage/UGC/best-seller-print-4x6.webp`,
  },
  {
    id: '2',
    productId: 'poster_11x14',
    title: 'Photo Poster',
    subtitle: '11x14',
    url: '/product/posters?type=normal&name=11x14',
    video: `${getBasePath()}/images/homepage/UGC/UGC_2.mp4`,
    productImage: `${getBasePath()}/images/homepage/UGC/best-seller-poster-11x14.webp`,
  },
  {
    id: '3',
    productId: 'doublesided_standard_flat_card_5x7_bundle_20',
    bundle: true,
    title: 'Card Smurfs™ Love You',
    subtitle: 'Double-sided 5x7',
    url: '/cards/template/703',
    video: `${getBasePath()}/images/homepage/UGC/UGC_3.mp4`,
    productImage: `${getBasePath()}/images/homepage/UGC/smurfs_LoveYou.webp`,
  },
  {
    id: '4',
    productId: 'doublesided_standard_flat_card_5x7_bundle_20',
    bundle: true,
    title: 'Card Strawberry Shortcake™',
    subtitle: 'Double-sided 5x7',
    url: '/cards/template/966',
    video: `${getBasePath()}/images/homepage/UGC/UGC_4.mp4`,
    productImage: `${getBasePath()}/images/homepage/UGC/card_Strawberry.webp`,
  },
];

interface Props {
  products: Product[];
}

export default function PictaUsers({ products }: Props) {
  const [currentVideoId, setCurrentVideoId] = useState<string | undefined>(
    PICTA_USERS_VIDEO[0]?.id,
  );

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const handleSelect = (videoId?: string) => {
    if (!videoId) return;

    setCurrentVideoId(videoId);
  };

  const onEnded = () => {
    const currentVideoIndex = PICTA_USERS_VIDEO.findIndex(
      (video) => video.id === currentVideoId,
    );
    const nextVideoIndex = currentVideoIndex + 1;
    const nextVideo = PICTA_USERS_VIDEO[nextVideoIndex];

    if (nextVideo) {
      setCurrentVideoId(nextVideo.id);
    } else {
      const firstVideo = PICTA_USERS_VIDEO[0];
      setCurrentVideoId(firstVideo?.id);
    }
  };

  return (
    <SendLogOnViewWrapper event="ui_homepage_ugc_sections_viewed" full>
      <HomePageSection title="What They’re Saying (and Printing!)">
        <div className={styles.layout}>
          {PICTA_USERS_VIDEO.map((video) => {
            const isActive = isMobile ? true : video.id === currentVideoId;
            const activeClass =
              video.id === currentVideoId ? styles.active : undefined;
            const product = products.find((p) => p.id === video.productId);
            if (!product) return null;
            const price = getProductLowerUnitPrice(product);
            return (
              <div
                key={video.id}
                className={clsx(styles.videoContainer, activeClass)}
              >
                <SendLogOnViewWrapper
                  event="ui_homepage_ugc_content_viewed"
                  value={{
                    id: video.id,
                    title: video.title,
                    subtitle: video.subtitle,
                    price,
                    url: video.url,
                  }}
                >
                  <Video
                    loop={isMobile}
                    hasSound
                    isPausable
                    handleSelect={handleSelect}
                    isActive={isActive}
                    className={styles.video}
                    onEnded={isMobile ? undefined : onEnded}
                    id={video.id}
                    logsDetails={{
                      page: 'homepage',
                      type: 'UGC',
                    }}
                  >
                    <source src={video.video} type="video/mp4" />
                  </Video>
                  <Overlay video={video} price={price} />
                </SendLogOnViewWrapper>
              </div>
            );
          })}
        </div>
      </HomePageSection>
    </SendLogOnViewWrapper>
  );
}
