'use client';

import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import Icon from '@/components/Icon';
import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

export type PictaUserVideo = {
  id: string;
  productId: string;
  bundle?: boolean;
  title: string;
  subtitle: string;
  video: string;
  url: string;
  productImage: string;
};

type Props = {
  video: PictaUserVideo;
  price: number;
};

export default function Overlay({ video, price }: Props) {
  return (
    <div className={styles.overlay}>
      <Link
        href={video.url}
        onClick={() =>
          sendLog('ui_homepage_ugc_cta_product_tapped', {
            id: video.id,
            title: video.title,
            subtitle: video.subtitle,
            price,
            url: video.url,
          })
        }
        className={styles.productCta}
      >
        <div className={styles.productMainInfos}>
          <div className={styles.productImageContainer}>
            <Image
              src={video.productImage}
              width={60}
              height={60}
              className={styles.image}
              alt={`${video.title}-${video.subtitle}`}
            />
          </div>
          <div className={styles.productInfoContainer}>
            <div>
              <h3 className="calloutStrong">{video.title}</h3>
              <p className={clsx('captionMedium', styles.subtitle)}>
                {video.subtitle}
              </p>
            </div>
            <p className="footnoteMedium">
              from ${price}
              {video.bundle && <span>/card (Bundle)</span>}
            </p>
          </div>
        </div>
        <span className={styles.productIconContainer}>
          <Icon name="arrow" />
        </span>
      </Link>
    </div>
  );
}
