import styles from './styles.module.scss';

export default function Loading() {
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <div className={styles.image} />
      </div>
    </div>
  );
}
