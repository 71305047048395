'use client';

import Link from 'next/link';

import { sendLog } from '@/services/logs';

import { FooterLocation } from '../Footer/types';

import Loading from './Loading';

import styles from './styles.module.scss';

interface Props {
  location?: FooterLocation;
  termsOfUseURL?: string;
  privacyPolicyURL?: string;
}

function TermsConditions({
  location = 'checkout',
  termsOfUseURL,
  privacyPolicyURL,
}: Props) {
  return termsOfUseURL && privacyPolicyURL ? (
    <div className={styles.termsContainer}>
      <p className={styles.text}>
        By {location === 'checkout' ? 'ordering' : 'using this website'}, I
        agree to be bound by the{' '}
        <Link
          className={styles.mentionsTerms}
          href={termsOfUseURL}
          target="_blank"
          aria-label="Terms of use"
          data-testid="terms-of-use"
          onClick={() => sendLog(`ui_${location}_termsOfUseTapped`)}
        >
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          className={styles.mentionsTerms}
          href={privacyPolicyURL}
          target="_blank"
          aria-label="Privacy policy"
          data-testid="privacy-policy"
          onClick={() => sendLog(`ui_${location}_privacyPolicyTapped`)}
        >
          Privacy Policy
        </Link>
      </p>
    </div>
  ) : (
    <Loading />
  );
}

export default TermsConditions;
